import parse from "html-react-parser";
import { AppButton } from "./atoms/button";
import { default as NextLink } from "next/link";
import Image from "next/image";
import { Stack, Heading, Text, Link, AspectRatio } from "@chakra-ui/react";
import { PostLiContent, PostListMeta } from "../styles/contentStyles";
import { ChakraImage } from "./atoms/ChakraImage";

export default function PostCard(props) {
  return (
    <>
      <li key={props.uri}>
        <Stack
          direction={["column", "column", "row", "row"]}
          className="post-list-item"
          itemScope
          itemType="http://schema.org/Article"
        >
          <AspectRatio ratio={16 / 9} width={{ base: "100%", md: "50%" }}>
            <ChakraImage
              src={props.featuredImage.src}
              alt={props.featuredImage.alt}
              layout="fill"
              objectFit="cover"
              maxHeight="16rem"
              itemProp="image"
            />
          </AspectRatio>
          <PostLiContent width={{ base: "100%", md: "50%" }}>
            <header>
              <Heading as="h3" fontSize="1.4rem" color="brand.600">
                <Link
                  as={NextLink}
                  href={props.uri}
                  itemProp="url"
                  color="brand.600"
                >
                  <a itemProp="headline">{parse(props.title)}</a>
                </Link>
              </Heading>
              <PostListMeta
                direction="row"
                color="gray.500"
                spacing="4px"
                pt={2}
                _hover={{ color: "gray.600" }}
              >
                <Text as="small" size="sm">
                  {"by "}
                  <Text as="small" size="sm" itemProp="author">{props.author.name}</Text>
                  {/* <Link as={NextLink} href={props.author.uri}>
                    <a itemProp="author">{props.author.name}</a>
                  </Link> */}
                </Text>
                {props.categories && (
                  <Text as="small" size="sm">
                    |
                  </Text>
                )}
                {props.categories &&
                  props.categories.map((cat, index, cats) => {
                    return (
                      <Text as="small" size="sm" key={index}>
                        <Link as={NextLink} href={cat.uri}>
                          <a>{cat.name}</a>
                        </Link>
                        {index < cats.length - 1 && ","}
                      </Text>
                    );
                  })}
              </PostListMeta>
            </header>
            <Text
              as="section"
              fontSize="sm"
              isTruncated
              whiteSpace="normal"
              noOfLines={4}
              itemProp="description"
            >
              {parse(props.excerpt)}
            </Text>
            <AppButton
              url={props.uri}
              text="Read More"
              py={2}
              px={3}
              fontSize=".9rem"
              bgColor="gray.200"
              color="brand.600"
              textTransform="uppercase"
              width="fit-content"
              borderRadius="md"
              _hover={{
                bgColor: "brand.500",
                color: "gray.100",
              }}
            />
          </PostLiContent>
        </Stack>
        <Text
          itemProp="datePublished"
          _hidden
          visibility="hidden"
          display="hidden"
        >
          {props.date}
        </Text>
      </li>
    </>
  );
}
