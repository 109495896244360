import client from "../src/apollo-client";
import ArchiveTemplate from "../src/templates/ArchiveTemplate";
import { getMenus,getPostCount } from "../src/lib/dataFetch";
import {GET_POSTS} from "../src/queries/posts/get-posts"

export default function Home({ posts, menus, pageInfo }) {
  return (
    <ArchiveTemplate menus={menus} posts={posts} pageInfo={pageInfo} />
  );
}
export async function getStaticProps(context) {
  const postCount = await getPostCount();
  const pageCount = postCount.offsetPagination.total / 10
  // const {posts, pageInfo} = await getAllPosts();
  const data = await client.query({
    query: GET_POSTS,
    variables: {
      first: 10,
      last: null,
      after: "",
      before: "",
    }
  })
  const {nodes: posts, pageInfo} = data.data.posts;
  const menus = await getMenus();

  return {
    props: {
      posts,
      menus,
      pageInfo
    },
  };
}
