import { default as NextLink } from "next/link";
import { chakra, Button } from "@chakra-ui/react";

const AppLink = chakra(NextLink, {
  baseStyle: {
    bgColor: "gray.200",
    color: "brand.600",
    borderRadius: "md",
    textTransform: "uppercase",
    width: "fit-content",
    py: 3,
    px: 2,
    _hover: {
      bgColor: "brand.500",
      color: "gray.100",
    },
  },
});

export const AppButton = ({ text, url, ...rest }) => {
  return (
    <>
      <NextLink href={url} >
        <Button as="a" {...rest}>{text}</Button>
      </NextLink>
    </>
  );
};
