import parse from "html-react-parser";
import { default as NextLink } from "next/link";
import { Stack, StackDivider } from "@chakra-ui/react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PostCard from "../components/PostCard";

export default function ArchiveTemplate({ menus, posts, pageInfo }) {
  return (
    <Layout menus={menus} hasNext={pageInfo.hasNextPage} hasPrevious={pageInfo.hasPreviousPage} startCursor={pageInfo.startCursor} endCursor={pageInfo.endCursor}  >
      <Seo title="Automotive News & Events" />
      <Stack
        as="ol"
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        mb={4}
        p={8}
        listStyleType="none"
      >
        {posts.map((post, index) => {
          const title = post.title;
          const featuredImage = {
            src: post.featuredImage?.node?.sourceUrl,
            alt: post.featuredImage?.node?.alt || ``,
          };

          return (
            <>
              <PostCard
                key={post.uri}
                title={title}
                featuredImage={featuredImage}
                uri={post.uri}
                date={post.date}
                excerpt={post.excerpt}
                author={post.author.node}
                categories={post.categories.nodes}
              />
            </>
          );
        })}
      </Stack>
    </Layout>
  );
}
